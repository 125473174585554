import React from 'react'
import PropTypes from 'prop-types'

import Input from '~components/Form/fields/Input'

const Shipping = ({ index }) => {
  return (
    <div className='two-cols -no-wrap'>
      <Input name={`shippings[${index}][from]`} label='From (city)' />
      <Input name={`shippings[${index}][to]`} label='To (city)' />
    </div>
  )
}
Shipping.propTypes = {
  index: PropTypes.number
}

export default Shipping
