import styled from 'styled-components'
import { container, maxWidthContent } from '~styles/utils'

export const IntroStyle = styled.div`
  ${container()}
  ${maxWidthContent()}
  text-align: center;
  margin: 20px 0 130px;

  h2 {
    margin-bottom: 40px;
  }
}
`
