import React from 'react'
import FacebookSvg from '~images/facebook.svg'
import InstagramSvg from '~images/instagram.svg'
import TwitterSvg from '~images/twitter.svg'
import LinkedinSvg from '~images/linkedin.svg'
import { FooterStyle } from './style'

const Footer = () => {
  return (
    <FooterStyle>
      <div className='container'>
        <div className='copyright'>
          &copy; {new Date().getFullYear()} CSX Corporation
        </div>
        <div className='socials'>
          <a href='https://www.facebook.com/OfficialCSX' rel='noopener noreferrer' target='_blank'>
            <FacebookSvg />
          </a>
          <a href='https://www.twitter.com/csx' rel='noopener noreferrer' target='_blank'>
            <TwitterSvg />
          </a>
          <a href='https://www.instagram.com/officialcsx' rel='noopener noreferrer' target='_blank'>
            <InstagramSvg />
          </a>
          <a href='https://www.linkedin.com/company/csx-transportation/' rel='noopener noreferrer' target='_blank'>
            <LinkedinSvg />
          </a>
        </div>
        <a className='back' href='http://csx.com'>Return to csx.com</a>
      </div>
    </FooterStyle>
  )
}

export default Footer
