import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { times, get } from 'lodash'
import { TweenMax } from 'gsap'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'
import classNames from 'classnames'
import { ReCaptcha } from 'react-recaptcha-v3'
import serialize from 'form-serialize'

import request from '~utils/request'
import { recaptcha as recaptchaSettings } from '~src/settings'
import { showThanks } from '~state/app'
import { setAllFieldsDirty, isInfoFormValidSelector } from '~state/form'
import Input from '~components/Form/fields/Input'
import Shipping from '~components/Form/fields/Shipping'
import Button from '~components/Button'
import Common from './Common'
import { InfoFormStyle } from './style'

const plugins = [ScrollToPlugin]; // eslint-disable-line

const InfoForm = () => {
  const ref = useRef(null)
  const dispatch = useDispatch()

  const [nbShipping, setNbShipping] = useState(5)
  const [isRequesting, setIsRequesting] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [serverError, setServerError] = useState(null)
  const isValid = useSelector(state => isInfoFormValidSelector(state.form))
  
  const verifyCallback = (token) => {
    setRecaptchaToken(token)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      setIsRequesting(true)
      const postData = {
        recaptchaToken,
        type: 'quote',
        submission: serialize(ref.current, { hash: true })
      }
      request.post('/addSubmission', postData)
        .then(() => {
          setIsRequesting(false)
          dispatch(showThanks())
        })
        .catch(error => {
          setServerError(get(error, 'response.data.status', 'server error'))
          setIsRequesting(false)
        })
    } else {
      dispatch(setAllFieldsDirty())
      setTimeout(() => {
        // wait for class being added in nexttick
        const firstError = ref.current.querySelector('.-error')
        if (firstError) {
          TweenMax.to(window, 0.5, {
            scrollTo: { y: ref.current, offsetY: 50 },
            onComplete: () => {
              firstError.querySelector('input').focus()
            }
          })
        }
      }, 0);
    }
  }

  const addShipping = (e) => {
    e.preventDefault()
    setNbShipping(nbShipping + 1)
  }


  return (
    <InfoFormStyle ref={ref} className={classNames({ '-is-requesting': isRequesting })}>
      <Common />
      <fieldset>
        <Input name='volume' label='Volume to be shipped' />
        <Input name='when' label='When do you plan to begin shipments?' />
      </fieldset>
      <fieldset className='shippings'>
        <div className='two-cols -no-wrap'>
          <div className='as-h3'>Shipping origin</div>
          <div className='as-h3'>Shipping destination</div>
        </div>
        
        {times(nbShipping, i => (
          // <div className='two-cols -no-wrap' key={i}>
          //   <Input name={`shippings[${i}][from]`} label='From (city)' />
          //   <Input name={`shippings[${i}][to]`} label='To (city)' />
          // </div>
          <Shipping key={i} index={i} />
        ))}
      </fieldset>
      <div className='two-cols actions-container'>
        <button className='add' onClick={addShipping}>
          <span className='plus'></span>
          add another shipment
        </button>
        <Button className="submit" arrow label="submit" onClick={onSubmit} />
      </div>
      {serverError ? <p className='error'>An error occured: {serverError}</p> : null}
      <ReCaptcha
        sitekey={recaptchaSettings.key}
        action='info_form'
        verifyCallback={verifyCallback}
      />
    </InfoFormStyle>
  )
}

export default InfoForm
