import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { email as isEmail, nanpPhone as isPhone } from 'is_js'

import { setFieldValue, setFieldValidation, setFieldDirty } from '~state/form'
import { InputStyle } from './style'

const Input = ({ name, label, type, required }) => {
  const value = useSelector(state => state.form[name] === undefined ? '' : state.form[name])
  const isValid = useSelector(state => state.form.validation[name])
  const isDirty = useSelector(state => state.form.dirty[name])
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch()

  const onChange = (e) => {
    dispatch(setFieldValue({ field: name, value: e.target.value }))
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
    dispatch(setFieldDirty({ field: name, status: true }))
  }

  const validate = () => {
    if (required && !value) {
      dispatch(setFieldValidation({field: name, status: false}))
      return false
    }

    if (type === 'email' && value && !isEmail(value)) {
      dispatch(setFieldValidation({ field: name, status: false }))
      return false
    }

    if (type === 'tel' && value && !isPhone(value)) {
      dispatch(setFieldValidation({ field: name, status: false }))
      return false
    }

    dispatch(setFieldValidation({ field: name, status: true }))
    return true
  }

  useEffect(() => {
    // validate
    if (isDirty) {
      validate()
    }
  }, [value, isDirty])

  return (
    <InputStyle className={classNames({
      '-required': required, 
      '-error': isValid === false && isDirty === true,
      '-focused': isFocused,
      '-not-empty': value,
    })}>
      {label ? <label>{label}</label> : null}
      <input
        name={name}
        type={type}
        required
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InputStyle>
  )
}
Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
}
Input.defaultProps = {
  required: false,
  type: 'text',
}
export default Input
