import React, { useState, useRef } from 'react'
import { TransitionGroup, Transition } from 'react-transition-group'
import classNames from 'classnames'
import { TweenMax, Power1 } from 'gsap'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'

import Button from '~components/Button'
import InfoForm from './InfoForm'
import QuoteForm from './QuoteForm'
import { FormStyle } from './style'

const plugins = [ScrollToPlugin] // eslint-disable-line

const Form = () => {
  const [isInfoForm, setInfoForm] = useState(false)
  const [isQuoteForm, setIsQuoteForm] = useState(false)
  const ref = useRef(null)

  const onEnter = (node) => {
    TweenMax.fromTo(
      node, 
      0.5, 
      { opacity: 0, y: 30 }, 
      { opacity: 1, y: 0, ease: Power1.easeOut, delay: 0.5 }
    )
  }

  const onExit = (node) => {
    TweenMax.to(node, 0.5, { opacity: 0, y: 30, ease: Power1.easeOut })
  }

  const showInfo = () => {
    setIsQuoteForm(false)
    setInfoForm(true)
    scrollToForm()
  }
  const showQuote = () => {
    setInfoForm(false)
    setIsQuoteForm(true)
    scrollToForm()
  }

  const scrollToForm = () => {
    TweenMax.to(window, 0.5, { scrollTo: { y: "#form-scroll", offsetY: -45 } })
  }

  return (
    <FormStyle ref={ref}>
      <h3>How can we help?</h3>
      <div className='two-cols -no-wrap-tablet'>
        {/* Form has been inverted du to a client request, don't be surprised */}
        <Button
          square 
          onClick={showQuote} 
          className={classNames({ '-active': isQuoteForm })}
          label='get more information' 
        />
        <Button
          square
          onClick={showInfo}
          className={classNames({ '-active': isInfoForm })}
          label='get a quote' 
        />
      </div>

      <div>
        <TransitionGroup>
          {isInfoForm ? 
            <Transition
              key='info'
              appear
              onEnter={onEnter}
              onExit={onExit}
              timeout={500}
            >
              <InfoForm />
            </Transition> : null
          }
          {isQuoteForm ?
            <Transition
              key='quote'
              appear
              onEnter={onEnter}
              onExit={onExit}
              timeout={500}
            >
              <QuoteForm />
            </Transition> : null
          }
        </TransitionGroup>
      </div>
    </FormStyle>
  )
}
export default Form
