import React from 'react'
import PropTypes from 'prop-types'
import { IntroStyle } from './style'
const Intro = ({ prop1 }) => {
  return (
    <IntroStyle>
      <h2>Get Started</h2>
      <p>Use the form below to request more information or get a quote for your specific lanes and goods. We’ll be in touch to discuss how we can empower your business.</p>
      <span id='form-scroll' />
    </IntroStyle>
  )
}
Intro.propTypes = {
  prop1: PropTypes.string
}
Intro.defaultProps = {
  prop1: 'test'
}
export default Intro
