import styled from 'styled-components'
import theme from '~styles/theme'
import { font, transition, media } from '~styles/utils'

export const InputStyle = styled.div`
  margin-top: 60px;
  position: relative;
  ${font(theme.fonts.formats.input)}
  ${media.mobile`
    margin-top: 52px;
  `}
  

  input {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    border-bottom: 2px solid ${theme.colors.white};
    width: 100%;
    display: block;
    padding: 12px 0;
    outline: none;
    color: ${theme.colors.white};
    ${transition()}

  }
  input:-webkit-autofill {
    -webkit-text-fill-color: ${theme.colors.white};
    -webkit-box-shadow: inset 0 0 0px 9999px ${theme.colors.blue};

    :focus {
      -webkit-box-shadow: inset 0 0 0px 9999px ${theme.colors.focusBlue};
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 0 0 12px;
    height: 46px;
    display: flex;
    align-items: flex-end;
    transform-origin: left;
    ${transition()}
  }

  &.-required {
    label::after {
      content: '*';
    }
  }

  &.-focused, &.-not-empty {
    label {
      ${transition()}
      opacity: 0.7;
      transform: scale(0.619) translateY(-110%);
    }
  } 
  
  &.-focused {
    input {
      ${transition()}
      background: ${theme.colors.focusBlue};
      padding: 12px;
    }
  }

  &.-error {
    label, input {
      color: ${theme.colors.red};
    }
    input {
      border-color: ${theme.colors.red};
    }
  }
}
`
