import styled from 'styled-components'
import theme from '~styles/theme'
import { container, font, plus, media } from '~styles/utils'

export const FormStyle = styled.div`
  ${container()}
  margin: 100px auto;
  ${media.tablet`
    margin: 50px auto 30px;
  `}

  h3 {
    text-align: center;
    margin-bottom: 40px;
  }

  form {
    padding: 40px 0 70px;
    ${media.tablet`
      padding: 40px 0 0;
    `}

    &.-is-requesting {
      pointer-events: none;
      opacity: 0.5 !important;
    }
  }

  fieldset.common {
    margin-bottom: 80px;
    ${media.mobile`
      margin-bottom: 65px;
    `}
  }

  button.-arrow {
    ${media.belowTablet`
      margin-left: auto;
      margin-top: 40px;
    `}
  }

  .error {
    color: ${theme.colors.red};
    font-weight: 500;
    margin: 0;
  }
}
`

export const InfoFormStyle = styled.form`  
  .as-h3 {
    margin-top: 140px;
  }

  ${media.mobile`
    fieldset:nth-child(2) {
      margin: 65px 0;
    }
    .as-h3 {
      margin-top: 60px;
    }
  `}

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }

  .error {
    text-align: right;
    ${media.tablet`
      text-align: left;
    `}
  }

  .add {
    background: none;
    border: none;
    border-radius: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: ${theme.colors.white};
    ${font(theme.fonts.formats.h3)}
    display: flex;
    align-items: center;
    cursor: pointer;
    .plus {
      ${plus()}
      margin-right: 15px;
    }

    &:hover .plus {
      transform: rotate(180deg);
    }
  }
`

export const QuoteFormStyle = styled.form`
  .actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .error {
    margin: 0 15px;
  }
`
