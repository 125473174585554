import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { TransitionGroup, Transition } from 'react-transition-group'
import { TweenMax, Power1 } from 'gsap'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'
import { Controller as ScrollController, Scene } from 'scrollmagic'
import 'debug.addIndicators'

import { setScrollController, setShowHeader } from '~state/app'
import Layout from '~components/Layout'
import SEO from '~components/Seo'
import Header from '~components/Header'
import Intro from '~components/Intro'
import Form from '~components/Form'
import Footer from '~components/Footer'
import Button from '~components/Button'
import { recaptcha as recaptchaSettings } from '~src/settings'

const plugins = [ScrollToPlugin] // eslint-disable-line

const IndexPage = () => {
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const showThanks = useSelector(state => state.app.showThanks)
  const scrollController = useSelector(state => state.app.scrollController)
  const dispatch = useDispatch()

  useEffect(() => {
    loadReCaptcha(recaptchaSettings.key)
    return attacheScenes()
  }, [])


  const onEnter = (node) => {
    // Display thanks
    TweenMax.fromTo(node, 0.5, { opacity: 0 }, { opacity: 1, delay: 1.5})
  }
  
  const onExit = (node) => {
    // Scroll to top when form are submitted
    TweenMax.to(window, 0.5, { scrollTo: 0, ease: Power1.easeOut, onComplete: () => {
      document.body.classList.add('_force-scroll')
    }})
    // Hide header text to prevent onEnter animation
    TweenMax.set(node.querySelector('header .text-container'), { display: 'none' })
  }
  const attacheScenes = () => {
    const controller = new ScrollController({ addIndicators: false })

    const headerPinScene = new Scene({
      triggerElement: headerRef.current,
      triggerHook: 0,
    })
      .setPin(headerRef.current, { pushFollowers: false })
      .addTo(controller)

    const contentScene = new Scene({
      triggerElement: contentRef.current,
      triggerHook: 0.99
    })
      .on('enter', () => {
        dispatch(setShowHeader(false))
      })
      .on('leave', () => {
        dispatch(setShowHeader(true))
      })
      .addTo(controller)

    dispatch(setScrollController(controller))

    const destroy = () => {
      if (scrollController) {
        headerPinScene.destroy()
        contentScene.destroy()
        scrollController.destroy()
      }
    }

    return destroy()
  }

  return (
    <Layout>
      <SEO title='Form' />
        <TransitionGroup>
          {showThanks ?
            <Transition onEnter={onEnter} key='thanks' timeout={2000}>
              <Header isThanks>
                <h1>Thank you</h1>
                <p>We’ll be in touch within one business day <br />to discuss your request.</p>
                <Button
                  arrow
                  href='http://csx.com'
                  label='return to csx'
                  bgColor='blue'
                  hoverBgColor='orange'
                />
              </Header>
            </Transition>
            :
            <Transition onExit={onExit} key='form' timeout={2000}>
              <div>
                <div ref={headerRef}>
                  <Header>
                    <h1>Powered to perform</h1>
                    <p>Learn how CSX can help optimize your supply chain with high-performance rail.</p>
                  </Header>
                </div>
                <div className='content-container' ref={contentRef}>
                  <div className='grow' id="here">
                    <Intro />
                    <Form />
                  </div>
                  <Footer />
                </div>
              </div>
            </Transition>
          }
        </TransitionGroup>
    </Layout>
  )
}

export default IndexPage
