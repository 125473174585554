import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { TweenMax } from 'gsap'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'
import classNames from 'classnames'
import { ReCaptcha } from 'react-recaptcha-v3'
import serialize from 'form-serialize'

import request from '~utils/request'
import { recaptcha as recaptchaSettings } from '~src/settings'
import { showThanks } from '~state/app'
import { setAllFieldsDirty, isQuoteFormValidSelector } from '~state/form'
import Choice from '~components/Form/fields/Choice'
import Button from '~components/Button'
import Common from './Common'
import { QuoteFormStyle } from './style'

const plugins = [ScrollToPlugin]; // eslint-disable-line

const QuoteForm = () => {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const isValid = useSelector(state => isQuoteFormValidSelector(state.form))
  const [isRequesting, setIsRequesting] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [serverError, setServerError] = useState(null)

  const verifyCallback = (token) => {
    setRecaptchaToken(token)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      setIsRequesting(true)
      const postData = {
        recaptchaToken,
        type: 'info',
        submission: serialize(ref.current, { hash: true })
      }
      request.post('/addSubmission', postData)
        .then(() => {
          setIsRequesting(false)
          dispatch(showThanks())
        })
        .catch(error => {
          setServerError(get(error, 'response.data.status', 'Server error'))
          setIsRequesting(false)
        })
    } else {
      dispatch(setAllFieldsDirty())
      setTimeout(() => {
        // wait for class being added in nexttick
        const firstError = ref.current.querySelector('.-error')
        if (firstError) {
          TweenMax.to(window, 0.5, {
            scrollTo: { y: ref.current, offsetY: 50 }, 
            onComplete: () => {
              firstError.querySelector('input').focus()
            }
          })
        }
      }, 0);
    }
  }
  
  return (
    <QuoteFormStyle ref={ref} className={classNames({'-is-requesting': isRequesting})}>
      <Common />
      <Choice
        name='services'
        legend='What type of service are you requesting additional information regarding?'
        choices={[
          { label: '<span>Traditional rail service</span>: For businesses located on railroad tracks; you can load freight onto or into rail cars for CSX to deliver to its destination.' },
          { label: '<span>Intermodal service</span>: Freight solution leveraging multiple modes of transportation, such as a ship or truck and rail.' },
          { label: '<span>Transloading</span>: The transfer of bulk products, such as chemicals or dry goods, between rail cars and trucks at special terminals.' },
          { label: '<span>Warehousing</span>: Store products at a rail-served warehouse and enjoy the combined benefits of long-haul rail and short-haul truck delivery.' }
        ]}
        multiple
      />
      <Choice
        name='purpose'
        legend='I’m looking to...'
        choices={[
          { label: 'Learn more about CSX solutions' },
          { label: 'Understand logistics or how to activate service' },
          { label: 'Connect with my CSX account representative' },
          { label: 'Get a quote for rail service' },
          { label: 'Find a vacant rail-served location or build or expand a rail-served facility ' }
        ]}
      />
      <div className='actions-container'>
        {serverError ? <p className='error'>An error occured: {serverError}</p> : null}
        <Button arrow label="submit" onClick={onSubmit} />
      </div>
      <ReCaptcha
        sitekey={recaptchaSettings.key}
        action='info_form'
        verifyCallback={verifyCallback}
      />
    </QuoteFormStyle>
  )
}

export default QuoteForm
