import axios from 'axios'
import { api as apiSettings } from '~src/settings'

const request = axios.create({
  baseURL: apiSettings.baseURL,
  proxy: {
    host: 'localhost',
    port: 5001,
  },
})

export default request