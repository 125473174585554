import React from 'react'
import styled from 'styled-components'
import theme from '~styles/theme'
import { transition, font, media } from '~styles/utils'

export const ButtonStyle = styled(
  ({ tag, children, ...props }) => React.createElement(tag, { className: props.className, onClick: props.onClick, href: props.href }, children))`
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 20px 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${theme.sizes.buttonHeight}px;
  color: ${({ color }) => theme.colors[color]};
  cursor: pointer;
  ${transition()}
  ${media.mobile`
    margin: 20px 0 5px;
  `}

  &.-square {
    border: 2px solid ${theme.colors.white};
    ${font(theme.fonts.formats.cta)}

    &:hover, &.-active {
      background: ${theme.colors.white};
      color: ${theme.colors.blue};
      ${transition()}
    }
  }

  &.-arrow {
    width: 190px !important;
    position: relative;
    align-items: stretch;
    ${font(theme.fonts.formats.input)}

    span {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 2px solid ${({ bgColor }) => theme.colors[bgColor]};
      border-right: none;
      background: ${({ bgColor }) => theme.colors[bgColor]};
      padding: 0;
      z-index: 2;
      ${transition()}
    }

    &::after {
      content: '';
      display: block;
      top: 0;
      right: 0;
      width: ${theme.sizes.buttonHeight}px;
      height: ${theme.sizes.buttonHeight}px;
      transform: scale(0.707) rotate(45deg) ;
      background: ${({ bgColor }) => theme.colors[bgColor]};
      // border: ${2 / 0.707}px solid ${({ bgColor }) => theme.colors[bgColor]};
      border-bottom: none;
      border-left: none;
      margin-left: -${theme.sizes.buttonHeight / 2}px;
      ${transition()}
    }

    &:hover {
      &::after, span {
        background: ${({ hoverBgColor }) => theme.colors[hoverBgColor]};;
        color: ${({ hoverColor }) => theme.colors[hoverColor]};;
        ${transition()}
      }
    }
  }
}
`
