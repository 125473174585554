import React from 'react'
import PropTypes from 'prop-types'
import { ChoiceStyle } from './style'

function createMarkup(sentence) {
  return { __html: sentence };
}

const Choice = ({ name, legend, choices, multiple, required }) => {
  return (
    <ChoiceStyle>
      {legend ? <legend>{legend}</legend> : null}
      {choices.map(({ label, value }, i) => (
        <div key={i}>
          <input
            id={`${name}-${i}`}
            name={name}
            type={multiple ? 'checkbox' : 'radio'}
            value={value ? value : label} 
          />
          <label htmlFor={`${name}-${i}`} dangerouslySetInnerHTML={createMarkup(label)}></label>
        </div>
        
      ))}
    </ChoiceStyle>
  )
}
Choice.propTypes = {
  name: PropTypes.string.isRequired,
  legend: PropTypes.string,
  choices: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool
}
Choice.defaultProps = {
  multiple: false,
  required: false,
}
export default Choice
