import React from 'react'
import Input from '~components/Form/fields/Input'

const Common = () => {
  return (
    <fieldset className='common'>
      <div className='two-cols'>
        <Input label='Name' name='name' required />
        <Input label='Email' name='email' type='email' required />
        <Input label='Phone' name='phone' type='tel' />
        <Input label='Company name' name='companyName' required />
      </div>

      <Input label='What goods are you looking to ship or receive? Include STCC if known.' name='goods' />
    </fieldset>
  )
}

export default Common
