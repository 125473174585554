import styled from 'styled-components'
import theme from '~styles/theme'
import { font, transition, media } from '~styles/utils'

export const ChoiceStyle = styled.fieldset`
  ${font(theme.fonts.formats.input)}
  margin: 25px 0;
  ${media.tablet`
    margin: 0;
  `}
  ${media.mobile`
    margin: 5px 0;
  `}

  legend {
    padding: 60px 0 25px;
  }

  div {
    position: relative;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  label {
    display: block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 18px;
      height: 18px;
      border: 2px solid ${theme.colors.white};
      background: transparent;
      margin-right: 15px;
      margin-top: 0;
      flex-shrink: 0;
      ${transition()}
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -7px;
      left: -7px;
      width: 32px;
      height: 32px;
      background: ${theme.colors.focusBlue};
      opacity: 0;
      ${transition()}
    }

    span {
      display: contents;
      font-weight: 700;
    }
  }

  input[type=radio] + label {
    &::before, &::after {
      border-radius: 50%;
    }
  }

  input:checked + label {
    &::before {
      border-color: ${theme.colors.white};
      background: ${theme.colors.white};
      ${transition()}
    }
  }
  input:focus + label {
    &::after {
      opacity: 1;
      ${transition()}
    }
  }
}
`
