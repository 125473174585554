import styled from 'styled-components'
import theme from '~styles/theme'
import { media, ruleRatioVw, transition, font } from '~styles/utils'

export const FooterStyle = styled.footer`
  padding-top: 60px;
  padding-bottom: 60px;
  ${ruleRatioVw('padding-right', 75, theme.media.mobile )};
  ${ruleRatioVw('padding-left', 75, theme.media.mobile )};
  margin-top: -1px;
  ${font(theme.fonts.formats.footer)}
  
  .container {
    display: flex;
    align-items: center;
    ${media.mobile`
      flex-direction: column;

      > * {
        margin: 10px 0;
      }
    `}

    .socials {
      flex-grow: 1;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        display: block;
        margin: 0 15px;
      }

      svg g {
        fill: ${theme.colors.white} !important;
      }
    }

    .back {
      display: block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background: ${theme.colors.white};
        ${transition()}
      }

      &:hover {
        &::after {
          width: 0%;
          ${transition()}
        }
      }
    }
  }
`
