import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ButtonStyle } from './style'

const Button = (props) => {
  return (
    <ButtonStyle
      tag={props.href ? 'a' : 'button'} 
      onClick={props.onClick}
      href={props.href}
      bgColor={props.bgColor}
      hoverBgColor={props.hoverBgColor}
      color={props.color}
      hoverColor={props.hoverColor}
      className={classNames([{'-square': props.square, '-arrow': props.arrow}, props.className])}
    >
      <span>{props.label}</span>
    </ButtonStyle>
  )
}
Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  className: PropTypes.string,
  square: PropTypes.bool,
  arrow: PropTypes.bool,
  bgColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
}

Button.defaultProps = {
  bgColor: 'darkBlue',
  hoverBgColor: 'orange',
  color: 'white',
  hoverColor: 'darkBlue'
}

export default Button
